<script setup lang="ts">
import { SidebarTrigger } from "@/components/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { useTitleStore } from "@/stores/title.ts";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { routes } from "./routes.ts";

const route = useRoute();
const store = useTitleStore();

function updateHeaderFromRoute() {
  const { path, params } = route;

  // Find the main route
  const mainNav = routes.find((nav) => {
    const baseRoute = nav.route.split('/:')[0]; // Extract base route
    return path.startsWith(baseRoute);
  });

  if (mainNav) {
    store.title = mainNav.title;
    store.url = mainNav.route;

    // Handle subcategories if applicable
    if (mainNav.children && params.tab) {
      const subNav = mainNav.children.find((child) => child.route === params.tab);
      store.subtitle = subNav
        ? { title: subNav.title, value: params.tab }
        : { title: undefined, value: undefined };
    } else {
      store.subtitle = { title: undefined, value: undefined };
    }
  } else {
    // Fallback for unmapped routes
    store.title = undefined;
    store.subtitle = { title: undefined, value: undefined };
  }
}

// Initialize and watch for route changes
onMounted(updateHeaderFromRoute);
watch(route, updateHeaderFromRoute);
</script>

<template>
  <header class="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
    <div class="flex items-center gap-2 px-4">
      <SidebarTrigger class="-ml-1" />
      <Separator orientation="vertical" class="mr-2 h-4" v-if="store.isLoaded" />
      <Breadcrumb v-if="store.isLoaded">
        <BreadcrumbList>
          <BreadcrumbItem class="hidden md:block">
            <component :is="store.subtitle.value !== undefined ? BreadcrumbLink : BreadcrumbPage">
              {{ store.title }}
            </component>
          </BreadcrumbItem>

          <BreadcrumbSeparator class="hidden md:block" v-if="store.subtitle.value !== undefined" />
          <BreadcrumbItem>
            <BreadcrumbPage v-if="store.subtitle.value !== ''">
              <router-link :to="{
                name: store.url,
                params: { tab: store.subtitle.value },
              }">
                {{ store.subtitle.title }}
              </router-link>
            </BreadcrumbPage>
          </BreadcrumbItem>

        </BreadcrumbList>
      </Breadcrumb>
    </div>
  </header>
</template>

<style scoped>

</style>