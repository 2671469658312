import {
    BadgeDollarSign,
    Box,
    Boxes,
    PieChart, RotateCw,
    SettingsIcon,
    TableProperties,
    User
} from "lucide-vue-next";
import {type FunctionalComponent} from "vue";
import type { LucideProps } from "lucide-vue-next";
import { openDialog } from "./dialogs";

interface Route {
    title: string;
    route: string;
    icon: FunctionalComponent<LucideProps, {}, any, {}>;
    category: string;
    children?: {
        title: string;
        route: string;
    }[];
    open?: {
        func: Function,
        dialogName: string,
    }
}

const routes: Route[] = [
  {
    title: 'Orders',
    route: '/orders/:tab',
    icon: Boxes,
    category: "main",
    children: [
      { title: 'All', route: 'all' },
      { title: 'Incoming', route: 'incoming' },
      { title: 'Processing', route: 'processing' },
      { title: 'Closed Out', route: 'completed' },
    ],
  },
  {
    title: 'Inventory',
    route: '/inventory/:tab',
    icon: Box,
    category: "main",
    children: [
      { title: 'All', route: 'all' },
      { title: 'Active', route: 'active' },
      { title: 'Processing', route: 'processing' },
      { title: 'Sold', route: 'sold' },
      { title: 'Returns', route: 'returns' },
      { title: 'Miscellaneous', route: 'miscellaneous' },
      { title: 'Unlisted', route: 'unlisted' },
    ],
  },
  {
    title: 'Sales',
    route: '/sales/:tab',
    icon: PieChart,
    category: "main",
    children: [
      { title: 'Soled', route: 'soled' },
      { title: 'Miscellaneous', route: 'miscellaneous' },
    ],
  },
  { title: 'Users', route: '/users', icon: User, category: "main" },
  { title: 'Settings', route: '/settings', icon: SettingsIcon, category: "main" },
  {
      title: 'Bulk',
      route: '/bulk',
      icon: TableProperties,
      category: "tasks",
      open: {
          func: openDialog,
          dialogName: 'bulk',
      }
  },
  {
      title: 'Repricing',
      route: '/repricing',
      icon: BadgeDollarSign,
      category: "tasks",
      open: {
        func: openDialog,
        dialogName: 'repricing',
      }
  },
  {
      title: 'Syncing',
      route: '/syncing',
      icon: RotateCw,
      category: "tasks",
      open: {
        func: openDialog,
        dialogName: 'syncing',
      }
  },

];

export { routes, type Route };