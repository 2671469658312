import { defineStore } from "pinia";

const useTitleStore = defineStore('titleData', {
    state: () => ({
        title: undefined as string | undefined,
        subtitle: {
            title: undefined as string | undefined,
            value: undefined as string | undefined
        },
        url: undefined as string | undefined
    }),
    getters: {
        isLoaded: (state) => state.title !== undefined,
    }
})

export { useTitleStore }