<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter, SidebarGroup, SidebarGroupLabel, SidebarHeader, SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton,
  SidebarMenuSubItem, SidebarRail
} from "@/components/ui/sidebar";
import {Collapsible, CollapsibleContent, CollapsibleTrigger} from "@/components/ui/collapsible";
import {
  ChevronRight,
  ChevronsUpDown,
  LogOut,
  MoreHorizontal,
  Plus,
} from "lucide-vue-next";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";
import {onBeforeMount} from "vue";
import Repricing from "@/components/modals/Repricing.vue";
import {Dialog} from "@/components/ui/dialog";
import Bulk from "@/components/modals/Bulk.vue";
import { routes, type Route } from "./routes.ts"
import { closeDialog, dialogs} from "@/components/navigation/dialogs.ts";
import Syncing from "@/components/modals/Syncing.vue";

const userStore = useUserStore();
const router = useRouter();

function logout() {
  localStorage.removeItem('jwt');
  router.push({name: 'login'});
}

onBeforeMount(() => {
  if (!userStore._loaded) {
    userStore.fetchUser();
  }
})

const data = {
  // reduce routes by category
  main: routes.reduce<Route[]>((acc, route) => {
    if (route.category === 'main') {
      acc.push(route);
    }
    return acc;
  }, []),
  tasks: routes.reduce<Route[]>((acc, route) => {
    if (route.category === 'tasks') {
      acc.push(route);
    }
    return acc;
  }, [])
}

</script>

<template>
<Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton tooltip="Soled">
              <img src="/favicon.ico" alt="Soled" />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Dashboard</SidebarGroupLabel>
          <SidebarMenu>
            <Collapsible
              v-for="item in data.main"
              :key="item.title"
              as-child
              class="group/collapsible"
              :default-open="true"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger as-child>
                  <SidebarMenuButton :tooltip="item.title" v-if="!item.children">
                      <component :is="item.icon" />
                      <router-link :to="item.route" >
                        {{ item.title }}
                      </router-link>
                  </SidebarMenuButton>

                  <SidebarMenuButton :tooltip="item.title" v-else>
                      <component :is="item.icon" />
                      <span>{{ item.title }}</span>
                      <ChevronRight class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"/>
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent v-if="item.children && item.children.length > 0">
                  <SidebarMenuSub>
                    <SidebarMenuSubItem
                      v-for="subItem in item.children"
                      :key="subItem.title"
                    >
                      <SidebarMenuSubButton>
                        <router-link
                            :to="{
                              name: item.route,
                              params: { tab: subItem.route },
                            }"
                        >
                          {{ subItem.title }}
                        </router-link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          </SidebarMenu>
        </SidebarGroup>
        <SidebarGroup class="group-data-[collapsible=icon]:hidden">
          <SidebarGroupLabel>Tasks</SidebarGroupLabel>
          <SidebarMenu>
            <SidebarMenuItem
              v-for="item in data.tasks"
              :key="item.title"
            >
              <SidebarMenuButton as-child>
                <div>
                  <component :is="item.icon" />
                  <router-link :to="item.route">
                    {{ item.title }}
                  </router-link>
                </div>
              </SidebarMenuButton>
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <SidebarMenuAction show-on-hover>
                    <MoreHorizontal />
                    <span class="sr-only">More</span>
                  </SidebarMenuAction>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="w-48 rounded-lg" side="bottom" align="end" v-if="item.open !== undefined">
                  <DropdownMenuItem @click="item.open.func(item.open.dialogName)">
                    <Plus class="text-muted-foreground" />
                    <span>Start New</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <SidebarMenuButton
                  size="lg"
                  class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <Avatar class="h-8 w-8 rounded-lg">
                    <AvatarFallback class="rounded-lg">
                      {{ userStore.fullName.charAt(0).toUpperCase() }}
                    </AvatarFallback>
                  </Avatar>
                  <div class="grid flex-1 text-left text-sm leading-tight">
                    <span class="truncate font-semibold">{{ userStore.fullName }}</span>
                    <span class="truncate text-xs">{{ userStore.email }}</span>
                  </div>
                  <ChevronsUpDown class="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side="bottom" align="end" :side-offset="4">
                <DropdownMenuLabel class="p-0 font-normal">
                  <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar class="h-8 w-8 rounded-lg">
                      <AvatarFallback class="rounded-lg">
                        {{ userStore.fullName.charAt(0).toUpperCase() }}
                      </AvatarFallback>
                    </Avatar>
                    <div class="grid flex-1 text-left text-sm leading-tight">
                      <span class="truncate font-semibold">{{ userStore.fullName }}</span>
                      <span class="truncate text-xs">{{ userStore.email }}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem @click="logout">
                  <LogOut />
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>

  <Dialog :open="dialogs['repricing']" @close="closeDialog('repricing')">
    <Repricing @close="closeDialog('repricing')" />
  </Dialog>

  <Dialog :open="dialogs['bulk']" @close="closeDialog('bulk')">
    <Bulk @close="closeDialog('bulk')" />
  </Dialog>

  <Dialog :open="dialogs['syncing']" @close="closeDialog('syncing')">
    <Syncing @close="closeDialog('syncing')" />
  </Dialog>
</template>

<style scoped>

</style>