import {type Ref, ref} from "vue";

const dialogs: Record<string, Ref<boolean>> = {
    repricing: ref(false),
    bulk: ref(false),
    syncing: ref(false),
}

function closeDialog(name: string) {
    dialogs[name].value = false;
}

function openDialog(name: string) {
    dialogs[name].value = true;
}

export { dialogs, closeDialog, openDialog }